import DisplayInfo from './DisplayInfo.js'
import '../styles/ui.css'
import useVideoManager from '../hooks/useVideoManager.js'
import useUIManager from '../hooks/useUIManager.js'
import useCameraManager from '../hooks/useCameraManager.js'
import { useEffect, useState } from 'react'
import React from 'react'

let artistsdata = require('../artists-data.json')

const collectionToHTML = (collection, key) => {
  return collection.map((s, i) => {
    return (
      <React.Fragment key={key + '-' + i}>
        {i === 0 ? <p>{s}</p> : <p key={'bio-' + i}>{s}</p>}
        {i < collection.length - 1 && <br />}
      </React.Fragment>
    )
  })
}

function ArtistContent({ data }) {
  const bio = collectionToHTML(data.bio, 'bio')
  const artwork = collectionToHTML(data.artwork, 'artwork')
  const { email, instagram, web } = data.socialmedia

  return (
    <>
      <h1 className="about-title">{data.title}</h1>
      <h1 className="about-title">{data.open}</h1>
      <div className="about-text" id="about">
        {artwork}
        <br />
        {bio}
        <br />
        <a href={data.artsensation.url}>{data.artsensation.title}</a>
        <br />
        <a href={data.artscouncil.url}>{data.artscouncil.title}</a>
        <br />
        <br />
      </div>

      <div className="social-networks">
        {email ? (
          <a href={email}>
            <img alt="mail-src" src="UI/mail.png" />
          </a>
        ) : null}
        {instagram ? (
          <a href={instagram}>
            <img alt="insta-src" src="UI/insta.png" />
          </a>
        ) : null}
        {web ? (
          <a href={web} className="invert">
            <img alt="web-src" src="UI/Web.png" />
          </a>
        ) : null}
      </div>
    </>
  )
}

const defaultData = { openuppercase: 'GRÅT INTE ÖVER SPILLD MJÖLK' } //Text display for opening modal
function DefaultContent() {
  return (
    <>
      <div className="about-text" id="about">
        <p>
          Gråt inte över spilld mjölk – En konstutställning om mötet mellan
          människa, natur och teknologi.
        </p>
        <p>
          Det råder en överväldigande konsensus om klimatförändringarna och dess
          konsekvenser för livet på jorden - den verklighet som forskare varnat
          för i decennier är här. I grupputställningen ”Gråt inte över spilld
          mjölk” blickar vi dock inte tillbaka för att berätta om hur det gick
          till historiskt. Istället riktar vi strålkastarljuset mot samtiden och
          det som komma skall.
        </p>
        <p>
          De utvalda konstverken behandlar frågor om artificiell intelligens,
          konspirationsteorier, biologisk mångfald, stigande temperaturer,
          skogsbruk och -bränder, kärnkraft, fossila bränslen,
          urfolksrättigheter och vattnet som vi delar med alla andra organismer
          på planeten. Tillsammans ger de en ögonblicksbild av de omvälvande
          frågor och parametrar som definierar livet här och nu – i
          samhällskroppen och den egna.
        </p>
        <p>
          ”Gråt inte över spilld mjölk” visas även som en utställning på
          Västernorrlands museum. Samarbetet med CUBE är ett sätt för museet att
          sprida konsten utanför de fysiska väggarna. Här på nätet finns ett
          verk av varje konstnär presenterat i ett virtuellt rum som är
          tillgängligt för vem som helst, när som helst och var som helst – allt
          som behövs är en dator eller mobiltelefon och internetuppkoppling.
        </p>
        <p>
          Medverkande konstnärer: Birt Berglund, Susanne Fagerlund, Olof Marsja,
          Malin Lin Nordström, Tora Wallander och Queenning Zhao.
        </p>
        <p>Curator: Karolina Aastrup</p>
        <p>Utställningen produceras med stöd av Kulturrådet.</p>
        <p>Medverkande konstnärer:</p>
        <h1 className="about-title">Birt Berglund</h1>
        <p> Thought-Forms, 2022 </p>
        <h1 className="about-title">Malin Lin Nordström</h1>
        <p> Beach summer, 2020 </p>
        <h1 className="about-title">Susanne Fagerlund</h1>
        <p> THE DOPPELGÄNGER SERIES - Nature carved into our bones, 2022 </p>
        <h1 className="about-title">Tora Wallander</h1>
        <p> Soft resistance, 2018 </p>
        <h1 className="about-title">Olof Marsja</h1>
        <p> eNAN, 2020 </p>
        <h1 className="about-title">Queenning Zhao</h1>
        <p> About emberness, 2021 </p>
      </div>
    </>
  )
}

function UIAbout({ ...props }) {
  const setVisible = useVideoManager((state) => state.setVisible)
  const firstGesture = useUIManager((state) => state.firstGesture)
  const cameraAt = useCameraManager((state) => state.at)
  const [data, setData] = useState(defaultData)

  useEffect(() => {
    if (cameraAt) {
      setData(artistsdata[cameraAt])
    } else {
      setData(defaultData)
    }
  }, [cameraAt])

  return (
    <DisplayInfo
      openText={data.openuppercase}
      styleClass="about"
      show={false}
      {...props}
      onShowCallback={() => setVisible(false)}
      onHideCallback={() => {
        if (!firstGesture) return
        setVisible(true)
      }}
    >
      {cameraAt ? (
        data.bio ? (
          <ArtistContent data={data} />
        ) : null
      ) : (
        <DefaultContent />
      )}
    </DisplayInfo>
  )
}
export default UIAbout
