import { Html } from '@react-three/drei'
import { DoubleSide } from 'three'
import useVideoManager from '../hooks/useVideoManager'
import UIMesh from './UIMesh'
import useCameraManager from '../hooks/useCameraManager'
import * as THREE from 'three'
import { useLoader } from '@react-three/fiber'
import { useEffect, useState } from 'react'
import Vimeo from './Vimeo'
import '../styles/youtube.css'

function Thumbnail({ thumbnail, scale, className, videoId, visible }) {
  const texture = useLoader(THREE.TextureLoader, thumbnail)
  const [hovered, setHover] = useState(false)
  const [inc, setInc] = useState(0)
  const active = useVideoManager((state) => state.playing[videoId])

  const hover = (e) => {
    e.stopPropagation()
    setHover(true)
    setInc(0.5)
  }
  const unhover = (e) => {
    e.stopPropagation()
    setHover(false)
    setInc(0)
  }

  const [aspectRatio] = useState(texture.image.height / texture.image.width)

  return (
    <mesh
      visible={visible}
      scale={scale + inc}
      className={className}
      onPointerOver={hover}
      onPointerOut={unhover}
    >
      <planeBufferGeometry attach="geometry" args={[1, aspectRatio]} />
      <meshBasicMaterial
        attach="material"
        map={texture}
        color={hovered || active ? 'white' : '#white'}
      />
    </mesh>
  )
}

function Stream({ opts = {}, invert = 'false', thumbnail, ...props }) {
  const { videoId, url } = props
  const cameraAt = useCameraManager((state) => state.at)
  const videoVisible = useVideoManager((state) => state.visible)
  const { playVideo, setLoaded } = useVideoManager((state) => state)

  const { endTransition } = useCameraManager((state) => state)
  const videoPlaying = useVideoManager((state) => state.playing[videoId])
  useEffect(() => {
    if (endTransition && cameraAt === videoId) {
      playVideo(videoId)
    }
    //eslint-disable-next-line
  }, [endTransition])
  return (
    <>
      <UIMesh {...props}>
        <Thumbnail
          visible={!videoPlaying || !endTransition}
          videoId={videoId}
          thumbnail={thumbnail}
          scale={props.scale2}
        />

        <Html
          className={videoVisible ? 'content' : 'hide'}
          scale={props.scale}
          transform
          depthTest
          material={<meshStandardMaterial side={DoubleSide} />}
        >
          <div className="container">
            <div
              className={
                endTransition && cameraAt === videoId ? 'content' : 'hide'
              }
            >
              <Vimeo
                url={url}
                options={opts}
                videoId={videoId}
                onLoaded={() => setLoaded(videoId, true)}
              />
            </div>
          </div>
        </Html>
      </UIMesh>
    </>
  )
}

export default Stream
