import Stream from './Stream'
import useVideoManager from '../hooks/useVideoManager'
import useUIManager from '../hooks/useUIManager'
import { useEffect } from 'react'
import useCameraManager from '../hooks/useCameraManager'
import { isMobile } from 'react-device-detect'
const OFFSET = isMobile ? 15 : 10

const data = {
  video1: {
    url: `https://player.vimeo.com/video/817095055?h=010b30529a`,
    thumbnail: 'videos/olofmarsja.webp',
    position: [0, 0, -14],
    rotation: [0, 0, 0],
    scale: 1,
    scale2: 8.7,
    opts: {
      height: '390',
      width: '640',
      texttrack: 'fr',
      autoplay: false,
      controls: false,
    },
    offset: {
      x: 0,
      y: 0,
      z: OFFSET,
    },
  },
  video2: {
    url: `https://player.vimeo.com/video/817095706?h=83aedb7140`,
    thumbnail: 'videos/queenningzhao.webp',
    position: [-14, 0, 0],
    rotation: [0, Math.PI / 2, 0],
    scale: 1,
    scale2: 5.5,
    opts: {
      height: '390',
      width: '640',
      texttrack: 'fr',
      autoplay: false,
      controls: false,
    },
    offset: {
      x: OFFSET,
      y: 0,
      z: 0,
    },
  },
  video3: {
    url: `https://player.vimeo.com/video/817095815?h=4d23c16405`,
    thumbnail: 'videos/susannefagerlund.webp',
    position: [14, 0, 0],
    rotation: [0, -Math.PI / 2, 0],
    scale: 1,
    scale2: 7.3,
    opts: {
      height: '390',
      width: '640',
      texttrack: 'fr',
      autoplay: false,
      controls: false,
    },
    offset: {
      x: -OFFSET,
      y: 0,
      z: 0,
    },
  },
  video4: {
  url: `https://player.vimeo.com/video/817094334?h=114fd13a3c`,
    thumbnail: 'videos/birtberglund.webp',
    position: [0, 0, 14],
    rotation: [0, Math.PI, 0],
    scale: 1,
    scale2: 9.7,
    opts: {
      height: '390',
      width: '640',
      texttrack: 'fr',
      autoplay: false,
      controls: false,
    },
    offset: {
      x: 0,
      y: 0,
      z: -OFFSET,
    },
  },
  video5: {
    url: `https://player.vimeo.com/video/817096010?h=0bc5001523`,
    thumbnail: 'videos/torawallander.webp',
    position: [0, 14, 0],
    rotation: [Math.PI / 2, 0, 0],
    scale: 1,
    scale2: 13.,
    opts: {
      height: '390',
      width: '640',
      texttrack: 'fr',
      autoplay: false,
      controls: false,
    },
    offset: {
      x: 0,
      y: isMobile ? -OFFSET - 5 : -OFFSET,
      z: 0,
    },
  },
  video6: {
    url: `https://player.vimeo.com/video/817094625?h=ec89d5cef9`,
    thumbnail: 'videos/malinlinnordstrom.webp',
    position: [0, -14, 0],
    rotation: [-Math.PI / 2, 0, 0],
    scale: 1,
    scale2: 16.2,
    opts: {
      height: '390',
      width: '640',
      texttrack: 'fr',
      autoplay: false,
      controls: false,
    },
    offset: {
      x: 0,
      y: isMobile ? OFFSET + 5 : OFFSET,
      z: 0,
    },
  },
}

function Videos() {
  const { allLoaded, addVideo, stopVideos } = useVideoManager((state) => state)
  const setResource = useUIManager((state) => state.resourceLoaded)

  useEffect(() => {
    if (allLoaded) {
      setResource('video', true)
    }
  }, [allLoaded, setResource])

  useEffect(() => {
    Object.keys(data).map((key) => {
      const props = data[key]
      addVideo(key, false)
      addTarget(key, props.position)
      return null
    })

    // eslint-disable-next-line
  }, [data])

  const { reset, set, addTarget } = useCameraManager((state) => state)

  useEffect(() => {
    if (reset) {
      stopVideos()
    }
    // eslint-disable-next-line
  }, [reset])

  return (
    <group>
      {Object.keys(data).map((key) => {
        const props = data[key]

        return (
          <Stream
            key={key}
            {...props}
            videoId={key}
            callback={() => {
              set('at', key)
              set('save', true)
            }}
          />
        )
      })}
    </group>
  )
}

export default Videos
