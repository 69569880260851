import DisplayInfo from './DisplayInfo.js'
import UIAbout from './UIAbout.js'
import '../styles/ui.css'
import { isMobile } from 'react-device-detect'
import useVideoManager from '../hooks/useVideoManager.js'
import useAudioManager from '../hooks/useAudioManager'
import useUIManager from '../hooks/useUIManager.js'
import useCameraManager from '../hooks/useCameraManager.js'

let data = require('../data.json')

const Arrows = function () {
  return (
    <div className="arrows">
      <div className="arrows-row">
        <img alt="arrow-up" className="arrow" src="UI/Up.png"></img>
      </div>
      <div className="arrows-row">
        <img alt="arrow-left" className="arrow" src="UI/Left.png"></img>
        <img alt="arrow-down" className="arrow" src="UI/Down.png"></img>
        <img alt="arrow-right" className="arrow" src="UI/Right.png"></img>
      </div>
    </div>
  )
}

function UI(props) {
  const { helpContainer, cubeButton } = data
  const { mobile, desktop } = helpContainer

  const setVisible = useVideoManager((state) => state.setVisible)
  const updateUI = useUIManager((state) => state.update)
  const firstGesture = useUIManager((state) => state.firstGesture)

  const didLoad = (state) => {
    const v = Object.values(state.loadingResources).every(Boolean)
    return v
  }

  const resourcesLoaded = useUIManager(didLoad)

  const refs = useAudioManager((state) => state.audiosRef)

  const transition = useUIManager((state) => state.transition)
  const cameraAt = useCameraManager((state) => state.at)

  const { set, endTransition } = useCameraManager((state) => state)

  return (
    <>
      {resourcesLoaded ? (
        <main>
          <DisplayInfo
            hideClose={transition}
            id="helpContainer"
            src="UI/Question.png"
            styleClass="help"
            init={!firstGesture}
            onShowCallback={() => setVisible(false)}
            onHideCallback={() => {
              if (!firstGesture) return
              setVisible(true)
            }}
          >
            <p>{isMobile ? mobile[0] : desktop[0]}</p>
            <p>{isMobile ? mobile[1] : desktop[1]}</p>
            {isMobile ? null : <Arrows />}
            {transition ? (
              <button
                onClick={() => {
                  updateUI('firstGesture', true)
                  // Media callback
                  if (refs.audio) {
                    Object.values(refs).map((ref, i) => {
                      if (ref.source) {
                        const audioCtx = ref.source.context
                        if (audioCtx.state === 'suspended') {
                          audioCtx.resume().then(function () {
                            // console.log('playing')
                            ref.play(0)
                          })
                        }
                      }
                      ref.play(0)
                      return null
                    })
                  }
                }}
                className="enter"
              >
                Enter
              </button>
            ) : null}
          </DisplayInfo>
          <UIAbout {...props} />
          <button
            id="cubContainer"
            className="cube"
            type="button"
            onClick={() => window.open(cubeButton.webpage, 'blank')}
          >
            <img alt="cube-src" src="UI/Cube.png" />
          </button>
          {endTransition && cameraAt ? (
            <button
              className="back"
              onClick={() => {
                set('at', null)
                set('reset', true)
              }}
            >
              <img alt="back-src" src="UI/Left.png" />
            </button>
          ) : null}
        </main>
      ) : null}
    </>
  )
}

export default UI
