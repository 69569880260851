import create from 'zustand'
import * as THREE from 'three'

const useCameraManager = create((set) => ({
  focus: null,
  active: null,
  zoom: null,
  position: new THREE.Vector3(),
  look: new THREE.Vector3(),
  offset: { x: 0, y: 0, z: 0 },
  rotation: new THREE.Quaternion(),
  save: false,
  targets: {},
  init: false,
  at: null,
  reset: false,
  endTransition: false,
  set: (type, value) =>
    set((state) => {
      return {
        ...state,
        [type]: value,
      }
    }),
  addTarget: (id, position) =>
    set((state) => {
      state.targets[id] = position

      return state
    }),
}))

export default useCameraManager
