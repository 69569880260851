import { useState, useEffect, useRef } from 'react'
import Player from '@vimeo/player'
import '../styles/vimeoControls.css'
import useCameraManager from '../hooks/useCameraManager'
import useVideoManager from '../hooks/useVideoManager'
const video = {
  PLAYING: 1,
  PAUSE: 0,
}

/***
Playback controls Events: 
  
  bufferend
  bufferstart
  ended
  error
  loaded
  pause
  play
  playbackratechange
  progress
  seeked
  timeupdate
  volumechange

 ***/

function Vimeo({ url, options, videoId, onPlaying, onLoaded, showControls }) {
  const containerRef = useRef()
  const player = useRef(null)
  const [status, setStatus] = useState(null)
  const [localVolume, ] = useState(1)
  const toggleStatus = () => {
    if (video.PAUSE === status) {
      player.current.setVolume(localVolume);
      player.current.play()
    } else {
      player.current.pause()
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      const _player = new Player(`container-${videoId}`, {
        url: url,
        ...options,
      })

      _player.on('play', function () {
        setStatus(video.PLAYING)
        onPlaying()
      })

      _player.on('loaded', function () {
        onLoaded()
      })

      _player.on('pause', function () {
        setStatus(video.PAUSE)
      })
      player.current = _player
    }
    // eslint-disable-next-line
  }, [containerRef.current])

  const play = useVideoManager((state) => state.playing[videoId])
  const { endTransition } = useCameraManager((state) => state)

  useEffect(() => {
    if (!player) return
    if (play) {
      player.current.play()
      player.current.setVolume(localVolume)
    } else {
      player.current.pause()
    }
  }, [play, localVolume])

  return (
    <div  ref={containerRef} id={`container-${videoId}`}>
      <img
        alt="cover"
        className="cover-img"
        onClick={toggleStatus}
        src={ status === video.PAUSE && endTransition ? "vimeo-controls/demo-play.png" : "vimeo-controls/demo-empty.png"}
      ></img>
      <div
        className="controls"
        style={endTransition ? { display: 'flex' } : { display: 'none' }}
      >
      </div>
    </div>
  )
}

export default Vimeo
