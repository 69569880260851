import create from 'zustand'

const useVideoManager = create((set) => ({
  didLoad: {
    video1: false,
    video2: false,
    video3: false,
    video4: false,
    video5: false,
    video6: false,
  },
  visible: true,
  playing: {
    video1: false,
    video2: false,
    video3: false,
    video4: false,
    video5: false,
    video6: false,
  },
  allLoaded: false,
  setVisible: (value) =>
    set((state) => {
      return {
        ...state,
        visible: value,
      }
    }),

  setLoaded: (type, loaded) =>
    set((state) => {
      const newState = {
        didLoad: { ...state.didLoad, [type]: loaded },
      }

      const v = Object.values(newState.didLoad).every(Boolean)

      return { ...state, ...newState, allLoaded: v }
    }),
  addVideo: (id, init) =>
    set((state) => {
      return {
        ...state,
        [id]: { play: init },
      }
    }),
  playVideo: (id) =>
    set((state) => {
      const play = state.playing
      // eslint-disable-next-line
      for (const [key, _] of Object.entries(play)) {
        if (key === id) {
          state.playing[key] = true
        } else {
          state.playing[key] = false
        }
      }
      return {
        ...state,
      }
    }),
  stopVideo: (id) =>
    set((state) => {
      const play = state.playing

      // eslint-disable-next-line
      for (const [key, _] of Object.entries(play)) {
        if (key === id) {
          state.playing[key] = false
        }
      }
      return {
        ...state,
      }
    }),
  stopVideos: () =>
    set((state) => {
      const play = state.playing

      // eslint-disable-next-line
      for (const [key, _] of Object.entries(play)) {
        state.playing[key] = false
      }
      return {
        ...state,
      }
    }),
}))

export default useVideoManager
