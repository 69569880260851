import { useEffect, useMemo } from 'react'
import { TextureLoader, EquirectangularReflectionMapping, sRGBEncoding } from 'three'
import { useThree } from '@react-three/fiber'

function Skybox({ imageUrl }) {
  const { scene } = useThree()

  const loader = useMemo(() => new TextureLoader(), [])

  useEffect(() => {
    loader.load(imageUrl, (texture) => {
      texture.mapping = EquirectangularReflectionMapping
      texture.encoding = sRGBEncoding
      scene.background = texture
      scene.environment = texture
    })

    return () => {
      scene.background = null
      scene.environment = null
    }
  }, [scene, imageUrl, loader])

  return null
}

export default Skybox
