import React, { useState, useEffect } from 'react'
import * as ReactDOM from 'react-dom/client'
import './styles/index.css'
import './styles/onboarding.css'
import { isMobile } from 'react-device-detect'
import App from './App'
import UI from './components/UI.js'

import { useTransition, a } from 'react-spring'
import * as THREE from 'three'
import useUIManager from './hooks/useUIManager'
import useVideoManager from './hooks/useVideoManager'
const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

function Login({ callback }) {

  const handleChange = ({ target }) => {
    setValue(target.value);
  }

  const checkPassword = () => {
    if (value === 'dontcry') {
      callback();
    }
  }


  const [value, setValue] = useState("");
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const calculateCountdown = () => {
      const currentDate = new Date();
      const targetDate = new Date(2023, 3, 29); // Months are 0-indexed, so April is 3
      const remainingTime = targetDate - currentDate;

      if (remainingTime >= 0) {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        setCountdown(`Välkommen till ”Gråt inte över spilld mjölk”! Utställningen öppnar om ${days} dagar.`);
      } else {
        setCountdown('Utställningen "Gråt inte över spilld mjölk" har stängt.');
      }
    };

    const countdownInterval = setInterval(calculateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
      <h3 style={{ margin: '10px 10px 10px 10px', textAlign: 'center' }}>{countdown}</h3>
      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        <input style={{ display: 'flex', width: '200px', height: '20px', alignSelf: 'center' }}
          name='password'
          id='password'
          value={value}
          onChange={handleChange}
          onKeyUp={(event) => { if (event.code === 'Enter') { checkPassword() } }}
        />
        <button onClick={() => checkPassword()}
          id="login"
          style={
            {
              backgroundColor: 'black',
              border: '.05em solid white',
              color: 'white',
              height: '22px',
              textAlign: 'center',
              fontSize: '16px',
              margin: '10px 10px',
              padding: '10px 10px 10px -10px',
              cursor: 'pointer',
              letterSpacing: '0em',
              fontFamily: '"Courier New", Courier, monospace',
              alignSelf: 'center'
            }
          }>-></button>

      </div>
    </div>);
}

const UPPER_BOUND_TRANSITION = isMobile ? 50 : 300
function OpacityLayer() {
  const enter = useUIManager((state) => state.firstGesture)
  const setResource = useUIManager((state) => state.resourceLoaded)
  const update = useUIManager((state) => state.update)

  const [totalopacity, setTotalopacity] = useState(1)
  const [counter, setCounter] = useState(0)
  const [w1, setWidth1] = useState(0)
  const [w2, setWidth2] = useState(0)

  const didLoad = (state) => {
    const v = Object.values(state.loadingResources).every(Boolean)
    return v
  }
  const resourcesLoaded = useUIManager(didLoad)
  const setVisible = useVideoManager((state) => state.setVisible)

  useEffect(() => {
    THREE.DefaultLoadingManager.onLoad = () => {
      setResource('scene', true)
    }
    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      setWidth1((itemsLoaded / itemsTotal) * 50)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (enter) {
      setTotalopacity(0.75)
    }
    if (enter && resourcesLoaded) {
      if (counter < UPPER_BOUND_TRANSITION) {
        setCounter(counter + 1)
      }
      if (counter > UPPER_BOUND_TRANSITION * 0.9) {
        setVisible(true)
      }
      if (counter >= UPPER_BOUND_TRANSITION) {
        update('transition', false)
        setCounter(0)
      }
    }
  }, [
    setWidth1,
    setVisible,
    setResource,
    resourcesLoaded,
    counter,
    enter,
    totalopacity,
    update,
  ])

  const loadedVideos = useVideoManager((state) => state.didLoad)
  const numVideos = Object.values(loadedVideos).filter((v) => v).length
  useEffect(() => {
    setWidth2((numVideos / 6) * 50)
  }, [numVideos, loadedVideos])

  const transitions = useTransition(resourcesLoaded, {
    from: { opacity: 1, counter: 0 },
    leave: { opacity: 0 },
    update: { counter },
  })

  return (
    <div style={{ display: 'flex' }}>
      {transitions((props) => (
        <a.div
          className={'loading'}
          style={{
            opacity: (1 - counter / UPPER_BOUND_TRANSITION) * totalopacity,
          }}
        >
          <div
            className="loading-bar-container"
            style={{ opacity: 1 - counter / UPPER_BOUND_TRANSITION }}
          >
            <a.div className="loading-bar" style={{ width: `${w1 + w2}%` }} />
          </div>
        </a.div>
      ))}
    </div>
  )
}

function AppWrapper() {
  const transition = useUIManager((state) => state.transition)
  const [auth, setAuth] = useState(false)

  return (
    !auth ?
      <Login callback={() => setAuth(true)} />
      :
      <>
        <UI />
        <App />
         {transition ? <OpacityLayer /> : null}
      </>
  )
}

root.render(<AppWrapper />)
