//import Audio from './components/Audio.js'
import Controls from './components/CameraControls'
import Videos from './components/Videos.js'
import Lights from './components/Lights.js'
import Cube from './components/Cube.js'
import { Canvas } from '@react-three/fiber'
import ErrorBoundary from './components/ErrorBoundary.js'
import { hot } from 'react-hot-loader/root'
import useUIManager from './hooks/useUIManager.js'
import Skybox from './components/Skybox.js'


function App() {
  const x = 30
  const y = 30 
  const z = 30
  const cubeSize = { x, y, z }

  const transition = useUIManager((state) => state.transition)
  const cameraOptions = {
    far: 100,
    fov: 60,
    aspect: window.width / window.height,
    position: [12, 1, 12],
    rotation: [0.10651402595328875, 0.7661758723404811, -0.07856151004722654],
  }
  const imageUrl = "/starrysky.jpg"


  return (
    <>
      <ErrorBoundary>
        <Canvas dpr={[2, 1]} shadows camera={cameraOptions}>
          {!transition ? <Controls /> : null}
          {/* <Audio /> */}
          <Lights />
          <Cube size={cubeSize} />
          <Videos />
          <Skybox imageUrl={imageUrl} />
        </Canvas>
      </ErrorBoundary>
      {/* <GUIControls /> */}
    </>
  )
}

export default hot(App)
