import { useEffect, useRef, useState } from 'react'
import useCameraManager from '../hooks/useCameraManager'
function UIMesh(props) {
  const { children, position, checkInit } = props
  const meshRef = useRef()

  const [onMe, setOnMe] = useState(false)

  const zoom = useCameraManager((state) => state.zoom)

  const set = useCameraManager((state) => state.set)

  const updateControls = (focusRef, newZoom, active) => {
    const z = newZoom ? newZoom : !zoom

    set('zoom', z) //
    set('focus', { x: focusRef[0], y: focusRef[1], z: focusRef[2] })
    set('active', active)

    set('offset', props.offset)
    set('rotation', {
      x: props.rotation[0],
      y: props.rotation[1],
      z: props.rotation[2],
    })
  }

  const handleClick = (e) => {
    if (onMe) {
      updateControls(position, null, false)
      setOnMe(false)
    } else {
      updateControls(position, true, true)
      setOnMe(true)
      if (props.callback) {
        props.callback()
      }
    }
    if (handleClick) {
      set('init', false)
    }
  }
  const initialPosition = useCameraManager((state) => state.init)

  useEffect(() => {
    if (checkInit && initialPosition) {
      handleClick()
    }
    // eslint-disable-next-line
  }, [initialPosition])

  return (
    <mesh {...props} onClick={handleClick} ref={meshRef}>
      {children}
    </mesh>
  )
}

export default UIMesh
