// const Light = ({target,...props}) => {

//     return (
//             <pointLight
//         {...props}
//         shadow-mapSize-width={512}
//         shadow-mapSize-height={512}
//         castShadow

//             />
//     );
// };
function Lights(positions) {

    return (
            <>
            <ambientLight intensity={0.02}></ambientLight>
            {/* <Light 
        color="#ffffff"
        position={[0, 5, 0]}
        angle={2.}
        distance={0}
        decay={0}
        intensity={.7}
        penumbra={1.}
        target={[0, 0, 0]}
            /> */}
            {/* <Light 
        color="#4880b9"
        position={[0.6, 0.55, 1.6]}
        angle={2.}
        distance={6}
        decay={0.9}
        intensity={.3}
        penumbra={1.}
        target={[0, 0, 0]}
            />
            <Light 
        color="#d4308e"
        position={[1.6, 0.55, 0.6]}
        angle={2.}
        distance={6}
        decay={0.9}
        intensity={.4}
        penumbra={1.}
        target={[0, 0, 0]}
            />
            <Light
        color="#ffffff"
        position={[2, 2, 2]}
        angle={2.}
        distance={0}
        decay={0.}
        intensity={2.5}
        penumbra={0.9}
        target={[-2,0,2]}
            /> */}
        </>
    );
}

export default Lights;
