import CameraControls from 'camera-controls'
import * as THREE from 'three'
import { useEffect, useMemo, useState } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import { KeyboardKeyHold } from 'hold-event'
import useCameraManager from '../hooks/useCameraManager'

CameraControls.install({ THREE })

const KEYCODE = {
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
}

function Controls() {
  const pos = useCameraManager((state) => state.position)
  const {
    active,
    look,
    focus,
    offset,
    rotation,
    reset,
    save,
    set,
    at,
    endTransition,
  } = useCameraManager((state) => state)

  const camera = useThree((state) => state.camera)
  const gl = useThree((state) => state.gl)

  const controls = useMemo(() => {
    const controls = new CameraControls(camera, gl.domElement)
    controls.maxZoom = 20
    controls.minZoom = 0
    controls.maxDistance = 200
    return controls
    //eslint-disable-next-line
  }, [])

  const [leftKey] = useState(new KeyboardKeyHold(KEYCODE.ARROW_LEFT, 100))
  const [rightKey] = useState(new KeyboardKeyHold(KEYCODE.ARROW_RIGHT, 100))
  const [upKey] = useState(new KeyboardKeyHold(KEYCODE.ARROW_UP, 100))
  const [downKey] = useState(new KeyboardKeyHold(KEYCODE.ARROW_DOWN, 100))
  useEffect(() => {
    controls.enabled = !(at && endTransition)
    //eslint-disable-next-line
  }, [at, endTransition])

  useEffect(() => {
    leftKey.addEventListener('holding', function (event) {
      controls.truck(-0.002 * event.deltaTime, 0, true)
    })
    rightKey.addEventListener('holding', function (event) {
      controls.truck(0.002 * event.deltaTime, 0, true)
    })
    upKey.addEventListener('holding', function (event) {
      controls.forward(0.005 * event.deltaTime, true)
    })
    downKey.addEventListener('holding', function (event) {
      controls.forward(-0.005 * event.deltaTime, true)
    })

    return () => {
      leftKey.removeEventListener('holding', function (event) {
        controls.truck(-0.002 * event.deltaTime, 0, true)
      })
      rightKey.removeEventListener('holding', function (event) {
        controls.truck(0.002 * event.deltaTime, 0, true)
      })
      upKey.removeEventListener('holding', function (event) {
        controls.forward(0.005 * event.deltaTime, true)
      })
      downKey.removeEventListener('holding', function (event) {
        controls.forward(-0.005 * event.deltaTime, true)
      })
    }
    // eslint-disable-next-line
  }, [])

  return useFrame((state, delta) => {
    if (save) {
      controls.saveState()
      set('save', false)
      set('endTransition', false)
    }
    if (reset) {
      controls.reset(true)
      set('reset', false)
    }
    if (active) {
      const { x, y, z } = offset

      pos.set(focus.x + x, focus.y + y, focus.z + z)
      look.set(focus.x - x, focus.y - y, focus.z - z)

      state.camera.position.lerp(pos, 0.25)
      state.camera.updateProjectionMatrix()

      controls.setLookAt(
        state.camera.position.x,
        state.camera.position.y,
        state.camera.position.z,
        look.x,
        look.y,
        look.z,
        true,
      )
      controls.rotateAzimuthTo(rotation.y, true)
      controls.normalizeRotations()
      const focusV = new THREE.Vector3(focus.x + x, focus.y + y, focus.z + z)
      if (Math.abs(focusV.distanceTo(controls.getPosition())) < 0.1) {
        set('active', false)
        set('endTransition', true)
      }
    }

    return controls.update(delta)
  })
}

export default Controls
